import OnModalInitCs from './MauticOnModalInit/OnModalInitCs'
import OnModalInitEn from './MauticOnModalInit/OnModalInitEn'
import OnModalInitHu from './MauticOnModalInit/OnModalInitHu'
import OnRouteChangeCs from './MauticOnRouteChange/OnRouteChangeCs'
import OnRouteChangeEn from './MauticOnRouteChange/OnRouteChangeEn'
import OnRouteChangeHu from './MauticOnRouteChange/OnRouteChangeHu'
import OnWebLoadCs from './MauticOnWebLoad/OnWebLoadCs'
import OnWebLoadEn from './MauticOnWebLoad/OnWebLoadEn'
import OnWebLoadHu from './MauticOnWebLoad/OnWebLoadHu'

export const MauticOnWebLoad = (): void => {
  switch (process.env.LOCALE) {
    case `cs`:
      return OnWebLoadCs()
    case `hu`:
      return OnWebLoadHu()
    default:
      return OnWebLoadEn()
  }
}

export const MauticOnRouteChange = (): void => {
  switch (process.env.LOCALE) {
    case `cs`:
      return OnRouteChangeCs()
    case `hu`:
      return OnRouteChangeHu()
    default:
      return OnRouteChangeEn()
  }
}

export const MauticOnModalInit = (context: HTMLElement): void => {
  switch (process.env.LOCALE) {
    case `cs`:
      return OnModalInitCs(context)
    case `hu`:
      return OnModalInitHu(context)
    default:
      return OnModalInitEn(context)
  }
}
